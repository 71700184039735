import React from "react"
import Links from "../components/Links"
import Icons from "../components/Icons"

export default () => (
  <div id="index">
    <h1>Anina H. Mu</h1>
    <Links></Links>
    <Icons></Icons>
  </div>
)
