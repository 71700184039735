import React from "react"
import { Link } from "gatsby"
import "../styles/links.css"

class Links extends React.Component {
  render() {
    return (
      <div id="links">
        <span><Link to="/book-club" className="link">Anina's Book Club</Link></span>
      </div>
    )
  }
}

export default Links
