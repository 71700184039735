import React from "react"
import { IoLogoGithub, IoLogoLinkedin } from "react-icons/io"
import { MdInsertDriveFile } from "react-icons/md"
import "../styles/icons.css"

class Icon extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      link: props.link,
      badge: props.badge
    }
  }

  render() {
    return (
      <a
        className="icon"
        href={this.state.link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {this.state.badge}
      </a>
    )
  }
}

class Icons extends React.Component {
  render() {
    return (
      <div className="icons">
        <Icon
          link="https://www.linkedin.com/in/aninamu/"
          badge={<IoLogoLinkedin />}
        />

        <Icon
          link="https://github.com/aninamu"
          badge={<IoLogoGithub />}
        />

        <Icon
          link="https://www.dropbox.com/s/brmnhpsumkl29s9/Anina%20Mu%20Resume%202018.pdf?dl=0"
          badge={<MdInsertDriveFile />}
        />
      </div>
    )
  }
}

export default Icons
